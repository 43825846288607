<template>
  <div
    v-editable="blok"
    :class="[
      'flex-container',
      fullWidthClass,
      flexWrapClass,
      breakpointModifier,
    ]"
    :style="flexBoxStyle"
  >
    <component
      :is="childBlok.component"
      v-for="childBlok in blok.content"
      :key="childBlok._uid"
      :blok="childBlok"
    />
  </div>
</template>

<script lang="ts" setup>
import type { SbGridFlexStoryblok } from '@/types'

interface Props {
  blok: SbGridFlexStoryblok
}

const props = defineProps<Props>()

const breakpointModifier = computed(() => {
  if (!props.blok.breakpoint) {
    return ''
  }

  const reverseModifier = props.blok['reverse_order'] ? '-reverse' : ''

  return `flex-container--${props.blok.breakpoint}${reverseModifier}`.toLowerCase()
})

const flexBoxStyle = computed(() => {
  return {
    '--horizontal-alignment': props.blok.horizontal_alignment || 'flex-start',
    '--vertical-alignment': props.blok.vertical_alignment || 'flex-start',
    '--row-gap': `${props.blok.spacing_col}px` || 0,
    '--column-gap': `${props.blok.spacing_row}px` || 0,
  }
})

const fullWidthClass = computed(() => {
  return props.blok.full_width ? 'flex-container--full-width' : null
})

const flexWrapClass = computed(() => {
  return props.blok.flex_wrap ? 'flex-container--wrap' : null
})
</script>

<style lang="scss" scoped>
.flex-container {
  --horizontal-alignment: flex-start;
  --vertical-alignment: stretch;
  --row-gap: 0;
  --column-gap: 0;

  display: flex;
  justify-content: var(--horizontal-alignment);
  align-items: var(--vertical-alignment);
  row-gap: var(--row-gap);
  column-gap: var(--column-gap);

  &--reverse {
    flex-direction: column-reverse;
  }

  &--md {
    flex-direction: column;

    @media (min-width: $breakpoint-md) {
      flex-direction: row;
      justify-content: var(--horizontal-alignment);
      align-items: var(--vertical-alignment);
    }
  }

  &--lg {
    flex-direction: column;

    @media (min-width: $breakpoint-lg) {
      flex-direction: row;
      justify-content: var(--horizontal-alignment);
      align-items: var(--vertical-alignment);
    }
  }

  &--md-reverse {
    @extend .flex-container--md;

    flex-direction: column-reverse;

    @media (min-width: $breakpoint-md) {
      flex-direction: row;
    }
  }

  &--lg-reverse {
    @extend .flex-container--lg;

    flex-direction: column-reverse;

    @media (min-width: $breakpoint-lg) {
      flex-direction: row;
    }
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--full-width {
    width: 100%;
  }

  & > img {
    flex-shrink: 0;
  }
}
</style>
